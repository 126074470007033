<template>
    <div class="column is-12">
        <div :class="classes" @click.capture="selecionar">
            
            <div v-if="amostra.guiaId>0" class="column is-1">
                <small>Guia:</small>
                <br />
                <router-link class="is-block" :to="{ name: 'portalconvenioguia', params: { id: amostra.guiaId  }}" >
                    {{ this.amostra.guiaId  }}
                </router-link>  
            </div>            

            <div :class="{ 'column is-2' : (amostra.guiaId>0) ,'column is-3': (amostra.guiaId<=0 || amostra.guiaId==null ) }">
                <b-icon v-if="this.amostra.cancelada"
                        icon="close-circle"
                        size="is-medium"
                        class="is-pulled-right"
                        :title="$t('AMOSTRA.AMOSTRACANCELADA')"
                        type="is-danger"></b-icon>

                <b-icon v-if="this.amostra.invalidada"
                        icon="asterisk"
                        size="is-medium"
                        class="is-pulled-right"
                        :title="$t('AMOSTRA.AMOSTRAEXAMECANCELADA')"
                        type="is-danger"></b-icon>

                <b-icon v-if="this.amostra.incidencia"
                        icon="alert-rhombus"
                        size="is-medium"
                        class="is-pulled-right"
                        :title="$t('AMOSTRA.AMOSTRAINCIDENCIA')"
                        type="is-warning"></b-icon>

                <small>{{$t('AMOSTRA.CODIGOBARRAS')}}:</small>
                <br />
                <b class="guiaAmostraCodigoDeBarrasItem">{{this.amostra.codigoDeBarras}}</b>
                
            </div>

            <div v-if="mostraPaciente" :class="{ 'column is-2' : (amostra.amostraLote>0) ,'column is-4': (amostra.amostraLote<=0 || amostra.amostraLote==null ) }" >
                <small>{{$t('SISTEMA.PACIENTE')}}:</small>
                <br />
                <b>{{this.amostra.pacienteNome}}</b>
            </div>

            <div class="column is-2">
                <small>{{$t('AMOSTRA.MATERIAL')}}:</small>
                <br />
                <span>
                    {{this.amostra.material.nome}}
                </span>
            </div>
            <div class="column is-2">
                <small>{{$t('AMOSTRA.CONSERVANTE')}}:</small>
                <br />
                <span>
                    {{this.amostra.conservante.nome}}
                </span>
            </div>

            <div v-if="amostra.codigoEtiquetaPreColeta != null" class="column is-2">
                <small>{{$t('AMOSTRA.CODIGOETIQUETA')}}:</small>
                <br />
                <span>
                    {{this.amostra.codigoEtiquetaPreColeta}}
                </span>
            </div>

            <div v-if="amostra.codigoIntegracaoBarras != null  && geraEtiquetaPreColeta == false" class="column is-2">
                <small>{{$t('AMOSTRA.CODIGOINTEGRACAO')}}:</small>
                <br />
                <span>
                    {{this.amostra.codigoIntegracaoBarras}}
                </span>
            </div>

            <div v-if="amostra.amostraLote>0" class="column is-2">
                <small>{{$t('AMOSTRA.LOTE')}}:</small>
                <br />
                <span>
                    {{this.amostra.amostraLote}} 
                    <small>{{this.$moment(this.amostra.amostraLoteData).format('DD/MM/YYYY HH:mm') }}</small>
                </span>
            </div>            

            <div class="column is-1 is-pulled-right">
                <b-tag v-if="this.amostra.numero" type="is-dark" rounded>{{this.amostra.numero}}</b-tag>

                <span v-if="this.amostra.acondicionamento == 'Congelada'" class="tag is-pulled-right is-light" :title="$t('AMOSTRA.CONGELADO')">C</span>
                <span v-if="this.amostra.acondicionamento == 'Refrigerada'" class="tag is-pulled-right is-link" :title="$t('AMOSTRA.REFRIGERADA')">R</span>
                <span v-if="this.amostra.acondicionamento == 'Ambiente'" class="tag is-pulled-right is-primary" :title="$t('AMOSTRA.AMBIENTE')">A</span>
                <span v-if="amostra.guiaId>0" style="margin-left:5px;" >
                    <span v-if="this.amostra.amostraImpressao"  :title="$t('AMOSTRA.ETIQUETA')"><b-icon :icon="'printer'" type="is-success" ></b-icon></span>                        
                </span>                
            </div>

            <div class="column">
                <b-tag v-for="(item, index) in this.amostra.itens" v-bind:key="index"
                       :type="exameStatus(item)" :title="exameStatusTitle(item)">
                    {{ item.exame.apelido }}
                </b-tag>
            </div>

            <div class="column" v-if="geraEtiquetaPreColeta">
                <b-field :label="$t('AMOSTRA.IDENTIFICACAO')">
                    <b-input type="text" v-model="amostra.codigoEtiquetaPreColeta"></b-input>
                </b-field>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            amostra: Object,
            index: Number,
            selecionada: Boolean,
            mostraPaciente: Boolean,
            isPortal: Boolean,
            geraEtiquetaPreColeta: Boolean
        },
        data() {
            return {
                classes: this.defineClasses(),                
            };
        },
        watch: {
            selecionada: function () {
                this.classes = this.defineClasses()
            }
        },
        methods: {
            defineClasses() {
                return "columns is-multiline guiaAmostra" +
                    (this.amostra.incidencia ? " guiaAmostraComIncidencia" : "") +
                    (!this.amostra.itens.map(x => !x.cancelado).length > 0 ? " guiaAmostraInvalidada" : "") +
                    (this.amostra.cancelada ? " guiaAmostraCancelada" : "") +
                    (this.selecionada ? " guiaAmostraSelecionada" : "")
            },
            selecionar() {
                if (this.amostra.id != 0) {
                    this.$emit('selecionarAmostra', this.amostra)
                }
            },
            exameStatus(item) {
                return item.cancelado
                    ? "is-danger"
                    : item.resultadoAssinado
                        ? "is-success"
                        : item.resultadoDigitado
                            ? "is-info"
                            : ""
            },
            exameStatusTitle(item) {
                return item.cancelado
                    ? this.$t('AMOSTRA.EXAMECANCELADO')
                    : item.resultadoAssinado
                        ? this.$t('AMOSTRA.EXAMEASSINADO')
                        : item.resultadoDigitado
                            ? this.$t('AMOSTRA.EXAMEDIGITADO')
                            : ""
            }            
        }
    }

</script>