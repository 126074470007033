<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <b-modal :active.sync="isModalAgendamento" has-modal-card trap-focus aria-role="dialog" aria-modal full-screen>
            <modalNovoAgendamento :id="novo.id" :observacao="novo.observacao" :data.sync="novo.data" :habilitarVisaoDiario="habilitarVisaoDiario()"  
            :idTipoAgendamento.sync="novo.idTipoAgendamento" :idPaciente.sync="novo.idPaciente" :paciente.sync="novo.paciente" 
            :tipoAgendamento.sync="novo.tipoAgendamento" :funcionarioId.sync="novo.funcionarioId" :funcionariosAtivos.sync="novo.funcionariosAtivos" 
            @novo="adicionarAgendamento"></modalNovoAgendamento>
        </b-modal>
        <b-modal :active.sync="isModalImpressao" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <modalImpressaoAgendamento :id="imprimir.id" :modelos="model.comprovanteModelos" @imprimir="imprimirModelo"></modalImpressaoAgendamento>
        </b-modal>

        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns is-multiline is-mobile is-gapless">
                        <div v-show="config.agendaPermitirFiltrarTodos" class="column is-2">
                            <b-field>
                                <b-radio-button v-model="model.tipoAgendamentoIdFiltro" @input="loadData()" :native-value="0" >
                                    <span >Todos</span>
                                </b-radio-button>
                            </b-field>
                        </div>
                        <div class="column is-2" v-for="(tipo) in model.tiposAgendamentoAtivos" v-bind:key="tipo.id">
                            <b-field>
                                <b-radio-button  @input="loadData()" v-model="model.tipoAgendamentoIdFiltro" :native-value="tipo.id" >
                                    <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>
                                    <span>{{tipo.nome}}</span>
                                </b-radio-button>
                            </b-field>
                        </div>
                    </div>
                    <b-tabs position="is-centered" type="is-boxed" v-model="activeTab">
                        <b-tab-item>
                            <template slot="header">
                                <span> {{$t('AGENDA.MENSAL')}} </span>
                            </template>
                                 
                            <div class="columns" v-show="!isLoading">                                                                 
                                <calendar  is-expanded :locale="{ id: 'pt-BR', firstDayOfWeek: 1, masks: { weekdays: 'WWWW' } }" ref="calendar" @update:from-page="mesAnoAlterado" :from-page="{ month: this.mes, year: this.ano }" >
                                    <template slot="day-content" slot-scope="slot">                                        
                                        <div class="day-content " :class="{[slot.day.classes.join(' ')]: true, 'hoje': slot.day.isToday }" >
                                            <span :tabindex="slot.dayProps.tabIndex" :aria-label="slot.dayProps['aria-label']" class="is-pulled-left vc-day-content vc-focusable vc-font-medium vc-text-sm vc-cursor-pointer focus:vc-font-bold vc-rounded-full">{{slot.day.label}}</span>
                                            <div class="events is-clipped">
                                                <div class="event tile is-child notification" v-if="getEventos(slot.day).length" >
                                                    <agenda-item :eventos="getEventos(slot.day)" :tipoVisao.sync="tipoVisao" v-on:loadData="loadData()" @abrirImprimir="abrirImprimir" @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item>
                                                </div>
                                                <div class="botoes" v-if="habilitaAdicionar(slot) && !habilitarVisaoDiario()">
                                                    <b-button icon-left="plus" class="icone-adicionar" :title="$t('AGENDA.ADICIONAREVENTO')" :class="{'icone-adicionar-hoje': isHoje(slot.day)}" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: model.tipoAgendamentoIdFiltro} }" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </calendar>
                            </div>
                        </b-tab-item>
                        <b-tab-item v-if="habilitarVisaoDiario()">
                            <template slot="header">
                                <span>  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading"> 

                                <div v-if="isInRole('agenda-incluir-encaixe')" class="column is-12 " > 
                                    <b-button class="is-pulled-right" type="is-info" icon-left="arrow-collapse-vertical" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: model.tipoAgendamentoIdFiltro, encaixe:true} }">Encaixe</b-button>
                                </div>                                
                                                  
                                <div class="column is-12 has-text-centered whitebox" > 

                                        <b-button class="is-pulled-left"  icon-left="chevron-left" @click="addDays(-1);" ></b-button>                                    
                                        <span class="is-size-3"><b>{{$moment(dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(dataDiaFiltro)}}</b></span>                                     
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="addDays(1);" ></b-button> 

                                </div>                                 
                                
                                <agenda-item  v-if="eventosDia.length>0" :eventos="eventosDia" :tipoVisao.sync="tipoVisao" v-on:loadData="loadData()" @abrirImprimir="abrirImprimir" @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item>                                     
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                      
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </article>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";

    .day-content {
        min-height: 10rem;
        border: solid 1px rgba(203, 213, 224,0.5);
        border-radius: 4px;
        box-sizing: border-box;
        max-height: 10rem;
        overflow-y: auto;
    }

    .weekday-1, .weekday-7 {
        background-color: rgba(203, 213, 224,0.5);
    }

    .hoje {
        background-color: rgba($cyan,0.5);
    }

    .events {        
        margin-top: 2.3rem;
        .botoes {
            position: absolute;
            right:  20px;
            top: 0;
        }
        .event {
            margin: 0;
            padding: 0.1rem;
        }
    }

    .icone-adicionar {
        background-color: rgba($cyan,0.2);
        color: #000;
    }

  
</style>
<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';
    import modalNovoAgendamento from '@/components/atendimento/modalNovoAgendamento.vue';
    import modalImpressaoAgendamento from '@/components/atendimento/modalImpressaoAgendamento.vue';    
    import Calendar from 'v-calendar/lib/components/calendar.umd';
    import moment from 'moment';    
    import agendaItem from '@/components/atendimento/agenda-item.vue';
 

    export default {
        components: {
            titlebar,
            Calendar,
            modalNovoAgendamento,
            modalImpressaoAgendamento,
            agendaItem
            
        },

        computed: {

            ...mapState([                
                'config',
                'unidadeId',
                'componentes'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('AGENDA.AGENDA'),
                ]
            }
        },
        watch: {
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadData();                                                
            },
            activeTab: function(novoValor) {                               
              if(novoValor==0){
                this.mudarVisao('mes');
              }else{
                this.mudarVisao('dia');
              }
            }            
        },        
        data() {
            return {                                 
                model: {
                    tipoAgendamentoIdFiltro:0
                },                
                eventos: [],
                eventosDia: [],
                ano: null,
                mes: null,
                isLoading: false,
                isModalAgendamento: false,                
                isModalImpressao: false,                
                imprimir: { id: null },
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },             
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                horariosDia: [], 
                activeTab:0,               
                tipoVisao:'mes',                
            }
        },
        created() {
            try {
                this.isLoading = true;
                if (this.$route.query.ano) this.ano = parseInt(this.$route.query.ano);
                if (this.$route.query.mes) this.mes = parseInt(this.$route.query.mes);

                if (this.$route.query.dia) {
                    this.dataDiaFiltro = new Date(0)
                    this.dataDiaFiltro.setDate(this.$route.query.dia)
                    this.dataDiaFiltro.setMonth(this.mes - 1)
                    this.dataDiaFiltro.setFullYear(this.ano)
                    this.dataDiaFiltro.setHours(0,0,0,0)
                }         

                if (this.$route.query.tipoAgendamentoId)
                    this.model.tipoAgendamentoIdFiltro = this.$route.query.tipoAgendamentoId;
                                
                
            } catch (ex) {
                console.error(ex);
            }
        },
        mounted() {        
            this.componentes.sidebarIsOpened = false;
            this.loadData();
        },
        beforeDestroy() {
            this.componentes.sidebarIsOpened = true;
        },        
        methods: {


       atualizaUrl() {
                const url = new URL(window.location)

                if (this.tipoVisao == 'dia') {
                    url.searchParams.set('dia', this.dataDiaFiltro.getDate())
                    url.searchParams.set('mes', this.dataDiaFiltro.getMonth() + 1)
                    url.searchParams.set('ano', this.dataDiaFiltro.getFullYear())
                } else {
                    url.searchParams.set('mes', this.mes)
                    url.searchParams.set('ano', this.ano)
                }
                if (this.model.tipoAgendamentoIdFiltro)
                    url.searchParams.set('tipoAgendamentoId', this.model.tipoAgendamentoIdFiltro)

                    
                history.pushState(
                    {},
                    null,
                    url
                )
            },
            habilitarVisaoDiario(){
                
                if(!this.model.tipoAgendamentoIdFiltro || this.model.tipoAgendamentoIdFiltro==0 ){                                    
                    return false;
                }
        
                return (this.model.possuiEscalasCadatradas)
                        
            },
            habilitarAdicionarDiario(dataDiaFiltro) {
                
                const hoje = new Date();

                hoje.setHours(0, 0, 0, 0);
                dataDiaFiltro.setHours(0, 0, 0, 0);


                return (dataDiaFiltro.getTime() >= hoje.getTime());                
            },                          
            addDays(dia){
                this.dataDiaFiltro.setDate(this.dataDiaFiltro.getDate() + dia);                                
                this.loadData();                                
            },                        
            mudarVisao(visao){    
                this.tipoVisao = visao;                       
                this.loadData()
            },
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },   

            incluirNovoHorarioDia(evento, horario){
                

                const params = {};

                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId= evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario=  moment(horario).toDate(); 

                   params.permiteAlterar = !this.habilitarVisaoDiario();    

                //Editar Paciente
                if(evento.id && evento.id>0){
                    params.id = evento.id;
                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })
                    
                //Novo paciente
                }else{                                    
                    this.$router.push({
                        name: 'agendamento', params: params
                    })  

                }
              
            },
 
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora)
                }
            },
            loadData() {
                
                if(this.tipoVisao=='dia'){
                    this.loadDataDia();
                }else{                

                    const params = [];

                    if (this.ano != null) 
                        params.push(`ano=${this.ano}`)
                    if (this.mes != null) 
                        params.push(`mes=${this.mes}`)
                    if (this.model.tipoAgendamentoIdFiltro) 
                        params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoIdFiltro}`)
                    params.push(`unidadeId=${this.unidadeId}`)
                    this.isLoading = true;

                    try {
                        this.$http.get(`/api/agenda/agenda?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                if (data != null) {
                                    this.model = data;
                                    this.eventos = data?.lista
                                        ?.filter(d => d.id > 0)
                                        .map(d => {
                                            return this.getEvento(d);
                                        });
                                    this.atualizaUrl()                                        
                                }
                                                                                                   
                                                            
                            }).catch(e => {
                                throw e;
                            }).finally(() => {
                                this.$forceUpdate(); 
                                this.isLoading = false;
                            });                            
                    } catch (e) {
                        console.error(e);
                        this.isLoading = false;
                    }
                }
            },
            loadDataDia(){
                const params = [];
                
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format()}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if(this.model.tipoAgendamentoIdFiltro) params.push(`tipoAgendamentoId=${this.model.tipoAgendamentoIdFiltro}`);
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/AgendaDia?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.eventosDia = data?.lista                                    
                                    .map(d => {
                                        return this.getEvento(d);
                                    });
                                this.atualizaUrl()
                                   
                            }                            
                                                                                                                
                        }).catch(e => {
                            throw e;
                        }).finally(() => {
                            this.$forceUpdate(); 
                            this.isLoading = false;                                                      
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }                
                
            },            
            habilitaAdicionar(slot) {
                if (this.isHoje(slot.day)) return true;

                return moment(slot.day.id).isSameOrAfter(moment());
            },
            isHoje(day) {
                return day.id === (moment().format('YYYY-MM-DD'));
            },
            getEventos(day) {                
                return this.eventos?.filter(e => { return day.id === e.day });
            },
            abrirNovoEvento(day, novo = null) {
                let dataAgendamento = moment(day.id).toDate();
                //caso seja hoje, empurrar o horário do agendamento para uma hora à frente (Evitar problema com a data menor que a data atual)
                if (moment(dataAgendamento).isSameOrBefore(moment())) {
                    dataAgendamento = moment().add('hour', 1).set('m', 0).toDate();
                }

                this.novo = novo ? novo : {
                    data: dataAgendamento,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {},
                }                
                this.isModalAgendamento = true;
            },
            async adicionarAgendamento(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                params.push(`pacienteId=${evento.paciente.id}`);
                params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    })                     
                    this.loadData();
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },            
            mesAnoAlterado(event) {                
                if (!this.isLoading && (event.month != this.mes || event.year != this.ano)) {                    
                    this.mes = event.month;
                    this.ano = event.year;                    
                    this.loadData();
                }
            },
            selecionarAcao(item, data, dia) {                
                item.click(data, dia);
            },
            abrirImprimir(item) {                
                this.imprimir = { id: null }
                this.isModalImpressao = true;
                this.imprimir.id = item.id;
            },
            imprimirModelo(data) {
                const form = document.createElement("form");
                const inputId = document.createElement("input");
                inputId.name = 'id';
                const inputModelo = document.createElement("input");
                inputModelo.name = 'modelo';

                form.method = "POST";
                form.action = "/api/agenda/Agendamento";

                inputId.value = this.imprimir.id;
                inputModelo.value = data.nome;

                form.appendChild(inputId);
                form.appendChild(inputModelo);

                data.variaveis?.forEach(v => {
                    const input = document.createElement("input");
                    input.name = v.nome;
                    input.value = v.valor;
                    form.appendChild(input);
                });


                document.body.appendChild(form);
                form.setAttribute('target','_blank');
                form.submit();
            },
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            }        
        }
    }
</script>