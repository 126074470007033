<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class='box' >
            <div class="level">
                <div class="level-item">

                    <div>
                        <p class="heading">Guia</p>
                        <p class="title">

                            <router-link :to="{ name: 'guia', params: { id: this.id, agendamentoDashboard : agendamentoDashboard}}">
                                {{ this.id}}
                            </router-link>                      

                        </p>
                    </div>

                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">{{$t('SISTEMA.PACIENTE')}}</p>
                        <p class="title">{{this.model.agendaWorklist[0].pacienteNome}}</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">{{$t('SISTEMA.CONVENIO')}}</p>
                        <p class="title">{{this.model.agendaWorklist[0].convenioNome}}</p>
                    </div>
                </div>       
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">{{$t('SISTEMA.SOLICITANTE')}}</p>
                        <p class="title">{{this.model.agendaWorklist[0].medicoNome}}</p>
                    </div>
                </div>                                               
                <div class="level-item">
                    <b-button type="is-primary" @click="confirmarAlteracaoDataAgendas()">
                        <b-icon icon="calendar"></b-icon>  <span>Ir para data</span>
                    </b-button>                    
                </div>                  
                <div class="level-item">
                    <b-button type="is-primary" @click="abrirAgenda()">
                        <b-icon icon="calendar"></b-icon>  <span>Ir para agenda</span>
                    </b-button>                    
                </div>                
            </div>
        </div>        

        <div v-show="!isLoading" class="columns whitebox">                                     
            
                <div style="border-right: 2px #ced8ce solid;" class="column is-fullwidth-mobile p-0" v-for="(agenda, index) in model.agendaWorklist" v-bind:key="index">                                   
                        <div class="p-0 column is-12 has-text-centered has-background-white-ter" > 
                            <span class="is-inline">
                                <span :class="getClasseLabelExame(agenda)"><b>{{agenda.exameNome}}</b></span> 
                                <hr class="m-0 mb-2">                                    
                            </span>
                        </div>
                        <div style="min-height: 15rem">
                            <div class="p-0 column is-12 has-text-centered whitebox" > 
                                    <span class="is-inline">   
                                        <div class="buttons has-addons is-centered is-pulled-right mr-1">
                                            <b-button v-if="agenda.pacienteCheckin"  :title="$t('WORKLIST.REENVIARWORKLIST')"
                                            @click="reenviarWorklist(agenda)" size="is-small" icon-left="refresh"></b-button>                                                                            
                                            <b-button v-show="!agenda.agendamento"  :title="$t('WORKLIST.ENCAIXE')"
                                            @click="confirmarEncaixe(agenda)" size="is-small" icon-left="arrow-collapse-vertical"></b-button>                                                                                                                    
                                        </div>                                 
                                        <br>
                                    </span>

                                    <span v-if="!agenda.agendamento">
                                        <span class="column is-12">
                                            <span v-show="!agenda.agendamento">                                        
                                                <b-field  :label="$t('WORKLIST.SELECIONEAGENDA')" class="has-text-left">
                                                    <b-select v-model="agenda.tipoAgendamentoId"  @input="alterarAgenda(agenda)" expanded >
                                                        <template v-for="(item, index) in agenda.agendasVinculadas" >
                                                            <option v-bind:key="index" :value="item.id">
                                                                {{item.nome}}
                                                            </option>
                                                        </template>
                                                    </b-select>
                                                </b-field>                                               
                                            </span>
                                        </span>
                                        <span  class="column is-12">                                           
                                                <b-field  :label="$t('WORKLIST.SELECIONEEXECUTANTE')" class="has-text-left">    
                                                    <b-select  v-model="agenda.executanteId" expanded>
                                                        <option :value="null"></option>
                                                        <template v-for="(item, index) in funcionariosAtivos">
                                                            <option v-bind:key="index" :value="item.id">
                                                                {{item.nome}}
                                                            </option>
                                                        </template>
                                                    </b-select>                         
                                                </b-field>                
                                        </span>                                      
                                    </span>       
                                    <span v-else >
                                        <span class="column is-12">
                                            <b-field :label="$t('WORKLIST.AGENDA')" class="has-text-left">
                                                <span>{{ agenda.tipoAgendamentoNome }}</span> 
                                            </b-field>
                                        </span>
                                        <span class="column is-12">
                                            <b-field :label="$t('WORKLIST.EXECUTANTE')"  class="has-text-left">
                                                <span>{{ agenda.executanteNome }}</span> 
                                            </b-field>
                                        </span>              
                                        <span class="columns column is-12">

                                            <span class="column is-6">
                                                <b-field  :label="getLabelAgendaoEm(agenda)"  class="has-text-left">
                                                    <span>{{ agenda.agendamento }}
                                                        <a  href="#" class="has-text-grey" @click="confirmarCancelamento(agenda)" :title="$t('WORKLIST.CANCELARAGENDAMENTO')" >
                                                            <b-icon icon="close-circle" size="is-small"></b-icon>
                                                        </a>                                                        
                                                    </span> 
                                            
                                                </b-field>                                            
                                            </span>

                                            <span class="column is-6">
                                                <b-field :label="$t('WORKLIST.CHECKIN')" class="has-text-left">
                                                    <b-checkbox
                                                    v-if="agenda.agendamento"                            
                                                    v-model="agenda.pacienteCheckin"                            
                                                    :disabled="agenda.pacienteCheckin"
                                                    @input="fazerCheckin(agenda)"
                                                    expanded ></b-checkbox>                                                                                              
                                                </b-field>

                                            </span>

                                        </span>                                                            
                                                                                                                                                        
                                    </span>

                                                            
                                                                                        
                            </div>  
                        </div>     
                        <b-collapse :open="(!agenda.agendamento)" aria-id="agendaDias">
                            <template #trigger="props">
                                <b-button v-show="agenda.agendamento"
                                    label="Exibir Dias"
                                    size="is-small" icon-left="calendar"
                                    aria-controls="agendaDias"
                                    :aria-expanded="props.open" expanded />
                            </template>
                            <div>

                                <div class="column is-12 has-text-centered is-inline-block has-background-white-ter" > 
                                    <b-button class="is-pulled-left "  icon-left="chevron-triple-left" @click="proximoDiaVago(-1,agenda);" ></b-button>                                                                            
                                       <b-button class="is-pulled-left "  icon-left="chevron-left" @click="proximoDia(-1,agenda);" ></b-button>                                                                           
                                        <span class="is-size- is-inline-block mt-2">
                                            <a href="#" class="has-text-grey" title="Alterar Data da Agenda" @click="confirmarAlteracaoDataAgendas(agenda);">
                                                <b-icon icon="calendar" size="is-small"></b-icon>
                                            </a>                                                                             
                                            <b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY') }} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b>
                                        </span>                                     
                                        <b-button class="is-pulled-right "  icon-left="chevron-triple-right" @click="proximoDiaVago(1,agenda);" ></b-button>                                    
                                        <b-button class="is-pulled-right" icon-left="chevron-right"  @click="proximoDia(1,agenda);" ></b-button> 
                                        
                                </div>                                      
                                <agendar-item v-if="agenda.eventosDia.length>0" :eventos="agenda.eventosDia" :funcionarioId="agenda.executanteId"
                                    :itemId="agenda.itemId" :agendamentoId="(agenda.agendamentoId) ? agenda.agendamentoId : 0 "  
                                    @incluirNovoHorarioDia="incluirNovoHorarioDia" @loadDataDias="loadDataDias" ></agendar-item> 

                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div> 
                            </div>
                        </b-collapse>
                                          
                </div>                                                                                                                              
        </div>     

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                :loading="salvandoDados"
                                @click="salvarWorklist"
                                icon-left="check-circle">
                        {{$t('WORKLIST.SALVAR')}}
                    </b-button>
                </p>
            </div>
        </nav>             

    </section>
</template>

<style lang="scss" scoped>
 .control{
    display: contents;
 }
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import agendarItem from '@/components/atendimento/agendar-item.vue';    
    import moment from 'moment';  
    import modalCancelandoAgenda from '@/components/atendimento/modalCancelandoAgenda'
    import modalAlterarDataAgendamento from '@/components/atendimento/modalAlterarDataAgendamento.vue'
    import modalAlterarDataEncaixe from '@/components/atendimento/modalAlterarDataEncaixe.vue'


    export default {
        data() {
            return {                
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                convenioCoparticipacao: this.$route.params.convenioCoparticipacao,
                agendamentoDashboard: this.$route.params.agendamentoDashboard,
                selected:null,
                model: [],
                tipoAgendamentoIdFiltro: 27,                
                isLoading: false,                  
                isModalAgendamento: false,                                
                imprimir: { id: null },  
                funcionariosAtivos: [],
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },                                                          
            }
        },
        components: {
            titlebar,
            agendarItem                             
        },
        computed: {
            titleStack() {
                return [
                    this.$t('WORKLIST.ATENDIMENTO'),
                    this.$t('WORKLIST.WORKLIST'),
                    this.id
                ]
            },
            ...mapState([
                'config',
                'unidadeId'
            ])
        },        
        created() {
            this.salvandoDados = true;            

            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
            .then(res => {
                if (res.data != null) {
                    this.funcionariosAtivos = res.data
                    this.carregarWorklist();
                }
            })
                        
                   
        },
        methods: {

            getClasseLabelExame(agenda){
                let classes = "is-size-4 is-inline-block mt-2 ";
                if(agenda.agendamento && agenda.agendamentoTexto=="Hoje")
                    classes += "has-text-success";

                return classes;
            },
            getLabelAgendaoEm(agenda){
                let label =  this.$t('WORKLIST.AGENDADOEM');
            
                if(agenda.agendamento && agenda.agendamentoTexto)
                    label += ' - ' +  agenda.agendamentoTexto;    
                

                return label;
            },
            confirmarCancelamento(agenda){

                    this.$buefy.modal.open({
                        parent: this,
                        component: modalCancelandoAgenda,
                        props: {
                            id: agenda.agendamentoId,  
                            itemId: agenda.itemId                      
                        },
                        events: {
                            loadData: this.carregarWorklist
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })

            }, 
            abrirAgenda(){
                const routeData = this.$router.resolve({name: 'agenda'});
                window.open(routeData.href, '_blank');                
            },
            async agendaStatus(evento,acao) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                params.push(`acao=${acao}`);


                if (evento.itemId) params.push(`itemId=${evento.itemId}`);

                try {
                    const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.carregarWorklist();
                } catch (e) {
                    console.error(e);
                }

            },     
            async reenvioWorklist(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                if (evento.itemId) params.push(`itemId=${evento.itemId}`);
                
                try {
                    const url = `/api/agenda/reenviarWorklist?${params.join('&')}`;
                    const res = await this.$http.get(url);                    
                } catch (e) {
                    console.error(e);
                }

            },       
            confirmarEncaixe(agenda){
                        
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataEncaixe,
                    events: {
                        encaixePaciente: this.encaixePaciente
                    },   
                    props: {
                        agenda: agenda,
                    },                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            }, 
            encaixePaciente(agenda,novaData){

                const evento = {                    
                    tipoAgendamentoId: agenda.tipoAgendamentoId,                   
                    pacienteId: agenda.pacienteId,                        
                    funcionarioId: (agenda.executanteId) ? agenda.executanteId  : null,
                    itemId: (agenda.itemId) ? agenda.itemId : null,
                    encaixe: true,                        
                }

                this.incluirNovoHorarioDia(evento,novaData,false);
                
            },
            confirmarAlteracaoDataAgendas(agenda){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataAgendamento,
                    events: {
                        alteracaoDataAgendas: this.alteracaoDataAgendas
                    }, 
                    props: {
                        agenda:agenda                        
                    },                                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            },     
            alteracaoDataAgendas(novaData,agenda){
                if(agenda){
                    agenda.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));               
                    agenda.proximo = 0; 

                    this.carregarDataDia(agenda);
                    
                }else{
                    this.model.agendaWorklist.forEach(e => {
                        e.proximo = 0; 
                        e.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));     
                        
                    }); 

                    this.loadDataDias();
                }
               
                
            },                                
            reenviarWorklist(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('WORKLIST.WORKLIST'),
                    message: this.$t('WORKLIST.REENVIARWORKLIST'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                        this.reenvioWorklist({id: item.agendamentoId, itemId: item.itemId});
                    },
                                    
                })

                return true;
            },            
            fazerCheckin(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('WORKLIST.CHECKIN'),
                    message: this.$t('WORKLIST.CONFIRMARCHECKIN'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                        this.agendaStatus({id: item.agendamentoId, itemId: item.itemId},'checkin');
                    },
                    onCancel: () => {
                        item.pacienteCheckin = false;
                    },
                    
                })

                return true;
            },
            alterarAgenda(agenda){                
                agenda.proximo = 1;  
                agenda.dataDiaFiltro =  new Date(new Date().setHours(0,0,0,0));        
                this.carregarDataDia(agenda);

            },
            carregarWorklist(tipoAgendamentoId){

                const params = [];    
                if (this.$route.params.id) params.push(`id=${this.$route.params.id}`);                                                     
                if(tipoAgendamentoId) params.push(`tipoAgendamentoId=${tipoAgendamentoId}`);

                this.$http.get(`/api/agenda/Worklist?${params.join('&')}`)

                
                    .then(({ data }) => {
                        this.model = data;

                        this.model.agendaWorklist = data.agendaWorklist
                            .map(d => {
                                return {
                                    ...d,
                                    agendamento: (d.agendamento) ? this.$moment(d.agendamento).format('DD/MM/YYYY HH:mm') : null,                                    
                                    eventosDia: [],
                                    dataDiaFiltro:  new Date(this.$moment(d.dataDiaFiltro).toDate().setHours(0,0,0,0))                                  
                                }                                      
                            }); 

                        this.loadDataDias();
                        this.salvandoDados = false;
                                                                                                
                    })
                    .catch((error) => {
                        this.model = null
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    }) 
            },

            salvarWorklist(){

                    let finalizarAgendamento = true;
                    this.model.agendaWorklist.forEach(e => {
                        if(e.agendamento == null)
                           finalizarAgendamento = false; 

                        
                    });
                    
                    if(finalizarAgendamento){
                        
                            if(this.pagamentoGuia && this.agendamentoDashboard != "true" && (this.condicaoPagamento  != 0 || this.convenioCoparticipacao)){
                                this.$router.push({ name: 'contas-receber-particular', 
                                    params: { 
                                        guiaId: this.id, 
                                        condicaoPagamento: this.condicaoPagamento,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }
                            else if (this.id) {
                                this.$router.push({ name: 'workflow', 
                                    params: { 
                                        id: this.id,
                                        agendamentoDashboard: this.agendamentoDashboard
                                    }
                                });
                            }

                    }else{
                        this.$buefy.dialog.alert({
                            title: this.$t('WORKLIST.ATENCAO'),                            
                            message:  this.$t('WORKLIST.EXISTEMEXAMESPENDENTES'),
                            type: 'is-warning',
                            hasIcon: true
                        });
                    }                

                return true;
            },  

            getDayOfWeek(dataDiaFiltro){ 
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];                 
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },            
            
            proximoDia(dia,agenda){                              
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);                                                
                agenda.proximo = 0; 
                this.carregarDataDia(agenda);                                
            }, 
            proximoDiaVago(dia,agenda){                                   
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);
                agenda.proximo = dia;                                
                this.carregarDataDia(agenda);                                
            },             
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    pacienteId: this.model.agendaWorklist.pacienteId,                    
                    day: this.$moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: this.$moment(d.dataHora).format('HH:mm'),
                    data: this.$moment(d.dataHora)
                }
            },            
            carregarDataDia(a){

                    const params = [];    
                    if (a.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(a.dataDiaFiltro).format()}`);                                                     
                    if(a.tipoAgendamentoId) params.push(`tipoAgendamentoId=${a.tipoAgendamentoId}`);
                    this.isLoading = true;

                    try {
                        this.$http.get(`/api/agenda/AgendaDia?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                if (data != null) {
                                    a.eventosDia = data?.lista                                    
                                        .map(d => {
                                            return this.getEvento(d);
                                        });
                                    
                                    if(!a.agendamento && data.executanteId >0 ){
                                        a.executanteId = data.executanteId;
                                    }
                                    
                                }
                                this.isLoading = false;    
                                this.$forceUpdate();                                                                                  
                            }).catch(e => {
                                throw e;
                            }).finally(() => {                                    
                                
                                
                                    if(a.eventosDia.length==0){
                                        if(a.proximo>0){
                                            a.dataDiaFiltro.setDate(a.dataDiaFiltro.getDate() + 1); 
                                            this.carregarDataDia(a);
                                        }else if (a.proximo<0){
                                            a.dataDiaFiltro.setDate(a.dataDiaFiltro.getDate() - 1); 
                                            this.carregarDataDia(a);
                                        }                                                                                
                                        return;                                
                                    }                                                                                                                                                                                     
                                
                                
                            });
                            
                    } catch (e) {
                        console.error(e);
                        this.isLoading = false;
                    }    

            },
            loadDataDias(){
                
                this.model.agendaWorklist?.forEach(a => { 
                    
                    const params = [];    
                    if (a.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(a.dataDiaFiltro).format()}`);                                                     
                    if(a.tipoAgendamentoId) params.push(`tipoAgendamentoId=${a.tipoAgendamentoId}`);
                    this.isLoading = true;

                    try {
                        this.$http.get(`/api/agenda/AgendaDia?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                if (data != null) {
                                    a.eventosDia = data?.lista                                    
                                        .map(d => {
                                            return this.getEvento(d);
                                        });
                                    
                                    if(!a.agendamento && data.executanteId >0 ){
                                        a.executanteId = data.executanteId;
                                    }
                                    
                                }
                                this.isLoading = false;    
                                this.$forceUpdate();                                                                                  
                            }).catch(e => {
                                throw e;
                            }).finally(() => {                                    
                                return;                                
                            });
                            
                    } catch (e) {
                        console.error(e);
                        this.isLoading = false;
                    }                      
                        
                });

                              
            },  
            incluirNovoHorarioDia(evento, horario,bloqueio){      
            
                if(bloqueio){
                    this.adicionarAgendamento(evento);
                }else{
                    const item = {
                        id: evento.id,
                        paciente: {
                            nome: this.model.agendaWorklist[0].pacienteNome,
                            id: this.model.agendaWorklist[0].pacienteId,
                        },
                        tipoAgendamento: {
                            nome: evento.tipoAgendamentoNome,
                            id: evento.tipoAgendamentoId,
                            cor: evento.cor
                        },
                        idPaciente: evento.pacienteId,                    
                        idTipoAgendamento: evento.tipoAgendamentoId,                    
                        data: horario,
                        idUnidade : this.model.agendaWorklist[0].unidadeId,
                        funcionarioId: (evento.funcionarioId) ? evento.funcionarioId : null,
                        itemId: (evento.itemId) ? evento.itemId : null,
                        encaixe: evento.encaixe ? evento.encaixe : false,                        
                    }  
                                                
                    this.$buefy.dialog.confirm({
                        title: "Realizar Agendamento?",
                        message:
                            "Agendar o paciente <b>"+item.paciente.nome+"</b> na data de <b>" + moment(item.data).format('DD/MM/YYYY HH:mm') +  "</b> ?",
                        confirmText: "Confirmar",
                        type: "is-info",
                        hasIcon: true,
                        cancelText: "Cancelar",
                        onCancel: function () {
                            return;
                        },                           
                        onConfirm: () => {
                            this.adicionarAgendamento(item);

                        },
                        
                    });

                }
                                
            },
            
            async adicionarAgendamento(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                params.push(`pacienteId=${evento.paciente.id}`);
                params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
                params.push(`encaixe=${evento.encaixe}`);

                if (evento.idUnidade != null)
                    params.push(`unidadeId=${evento.idUnidade}`)
                else
                    params.push('unidadeid=');

                if (this.id && evento.observacao!='Bloqueio' ) params.push(`guiaId=${this.id}`);

                if (evento.itemId) params.push(`itemId=${evento.itemId}`);
                
                try {
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    });   

                    if(evento.observacao !='Bloqueio'){
                        this.carregarWorklist();                 
                    }else{
                        this.loadDataDias();
                    }
                                            
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },                                 
        }
    }
</script>