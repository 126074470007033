<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCANCELANDOAGENDA.CANCELARAGENDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALCANCELANDOAGENDA.ATENCAO')}}</h5>
                <p>{{$t('MODALCANCELANDOAGENDA.AVISOCANCELAMENTO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoAgenda"
                            type="is-danger">
                {{ erroCancelamentoAgenda }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDOAGENDA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoCancelamentoAgendaSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoAgenda" :key="index"
                                :value="motivo.descricaoInterno">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarCancelamentoAgenda">{{$t('MODALCANCELANDOAGENDA.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALCANCELANDOAGENDA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            id: Number,
            itemId: Number            
        },
        data() {
            return {
                procurarMotivosCancelamentoAgenda: '',
                listaMotivosCancelamentoAgenda: [],
                motivoCancelamentoAgendaSelecionado: null,
                erroCancelamentoAgenda: null,
            }
        },
        computed: {
            filteredMotivosCancelamentoAgenda() {
                return this.procurarMotivosCancelamentoAgenda
                    ? this.listaMotivosCancelamentoAgenda.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoAgenda.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoAgenda
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoAgenda()
        },
        methods: {
            loadListaMotivosCancelamentoAgenda() {
                this.$http.get('/api/search/MotivosCancelamentoAgenda')
                    .then(m => {
                        this.listaMotivosCancelamentoAgenda = m.data
                    })
            },
            async confirmarCancelamentoAgenda() {

                if(this.motivoCancelamentoAgendaSelecionado!=null){
                    const params = [];
                    const acao = 'cancelar';

                    if (this.id) params.push(`id=${this.id}`);
                    if (this.itemId) params.push(`itemId=${this.itemId}`);
                                        
                    params.push(`motivo=${this.motivoCancelamentoAgendaSelecionado}`);
                    params.push(`acao=${acao}`);

                    try {
                        const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                        const res = await this.$http.get(url);
                        this.$emit('loadData')                            
                        this.$emit('close')                        
                    } catch (e) {
                        console.error(e);
                    }
                }

            },
            

        }
    }
</script>