<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-8">
                                    <ValidationProvider name="centro de custo" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.CENTROCUSTO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('CONTASPAGARCADASTRO.CENTROCUSTO')" class="is-fullwidth" v-model="model.centroDeCusto.id">
                                                <option v-for="(item, index) in centrosDeCusto" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.DATA')">
                                        <b-datetimepicker v-model="model.data"
                                                          :disabled="model.id > 0"
                                                          :placeholder="$t('CONTASPAGARCADASTRO.CENTROCUSTO')"
                                                          icon="calendar-today"
                                                          :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                                          horizontal-time-picker expanded>
                                        </b-datetimepicker>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-8">
                                    <ValidationProvider name="descrição" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.DESCRICAO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.descricao"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.NOTAFISCAL')">
                                        <b-input type="text" v-model="model.notaFiscal"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider name="fornecedor" rules="required" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.FORNECEDOR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERFORNECEDOR')" class="is-fullwidth" v-model="model.fornecedor.id" :readonly="model && model.id > 0">
                                                <option v-for="(item, index) in fornecedores" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column" v-if="config.naoVincularContaPagarContaCorrente == false">
                                    <ValidationProvider rules="required" name="conta corrente" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONTASPAGARCADASTRO.CONTACORRENTE')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERCONTACORRENTE')" class="is-fullwidth" v-model="model.contaCorrente.id" :readonly="model && model.id > 0">
                                                <option v-for="(item, index) in contasCorrentes" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <search-id-name :leitura="true" v-show="model.id != 0" :id.sync="model.funcionarioId" :label="$t('CONTASPAGARCADASTRO.FUNCIONARIO')" table="Funcionario"></search-id-name>
                            <search-id-name :id.sync="model.unidadeId" :label="$t('CONTASPAGARCADASTRO.UNIDADE')" table="Unidade"></search-id-name>
                            <div class="columns">
                                <div class="column is-12">
                                    <b-field :label="$t('CONTASPAGARCADASTRO.OBSERVACAO')">
                                        <b-input maxlength="5000" type="textarea" v-model="model.observacoes"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">   
                            <historico-alteracoes :tabela="'Pagar'" :chave="model.id"></historico-alteracoes>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <div class="columns">
                                <article class="panel column">
                                    <div class="panel-heading has-text-centered">
                                        {{$t('CONTASPAGARCADASTRO.PARCELAS')}}
                                    </div>
                                    <div class="panel-block">
                                        <table class="table is-bordered is-striped is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th width="12%">{{$t('CONTASPAGARCADASTRO.VENCIMENTO')}}</th>                                                    
                                                    <th width="12%">{{$t('CONTASPAGARCADASTRO.VALORPARCIAL')}}</th>
                                                    <th width="12%">{{$t('CONTASPAGARCADASTRO.JUROS')}}</th>
                                                    <th width="12%">{{$t('CONTASPAGARCADASTRO.MULTA')}}</th>                                                    
                                                    <th width="12%">{{$t('CONTASPAGARCADASTRO.TOTAL')}}</th>
                                                    <th width="40%" >{{$t('CONTASPAGARCADASTRO.SITUACAO')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(parcela, index) in model.parcelas" v-bind:key="index">
                                                    <td>
                                                        <b-datepicker editable v-model="parcela.dataDeVencimento" trap-focus></b-datepicker>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.valorParcial"   @input="recalcularParcelas()"></b-input>
                                                    </td>                                                    
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.juros"   @input="recalcularParcelas()"></b-input>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.multa" @input="recalcularParcelas()"></b-input>
                                                    </td>
                                                    <td>
                                                        <b-input type="number" step=".00000001" v-model="parcela.valor"  readonly></b-input>
                                                    </td>                                                       
                                                    <td >
                                                        <div v-if="parcela.situacao == 'Pendente'" class="btn-group btn-group-xs" role="group">
                                                            <button type="button" class="button is-warning">{{parcela.situacao}}</button>
                                                            <button type="button" class="button is-success" :title="$t('CONTASPAGARCADASTRO.DEFINIRCOMORECEBIDO')"
                                                                    @click="pagarParcela(index);">
                                                                <i class="mdi mdi-check"></i>
                                                            </button>
                                                            <button v-if="isInRole('pagar-cancelar-parcela')" type="button" class="button is-danger"
                                                                    :title="$t('CONTASPAGARCADASTRO.CLIQUEPARACANCELAR')" @click="cancelarParcela(index);">
                                                                <i class="mdi mdi-close-circle"></i>
                                                            </button>
                                                        </div>
                                                        <table v-else-if="parcela.situacao == 'Pago'" width="100%">
                                                            <tr>
                                                                <td>
                                                                    <button type="button" class="button is-success btn-xs">{{$t('CONTASPAGARCADASTRO.RECEBIDO')}}</button>
                                                                </td>
                                                                <td style="font-size: 0.8em;" width="80%">
                                                                    <span v-if="parcela.dataDePagamento != null">
                                                                        em <b>{{$moment(parcela.dataDePagamento).format("DD/MM/YYYY HH:mm")}}</b>
                                                                    </span>
                                                                    por <b>{{parcela.funcionarioPagamento.nome}}</b>
                                                                    <br />
                                                                    Espécie: <b>{{parcela.especieDePagamento.nome}}</b>
                                                                    <span v-if="parcela.contaCorrente != null && parcela.contaCorrente.id != null">
                                                                        C/C: <b>{{parcela.contaCorrente.nome}}</b>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table v-else-if="parcela.situacao == 'Cancelado'" width="100%">
                                                            <tr>
                                                                <td>
                                                                    <button type="button" class="button is-danger btn-xs">{{$t('CONTASPAGARCADASTRO.CANCELADO')}}</button>
                                                                </td>
                                                                <td style="font-size: 0.8em;" width="80%">
                                                                    <span v-if="parcela.motivoCancelamento != null">
                                                                        {{$t('CONTASPAGARCADASTRO.MOTIVOCANCELAMENTO')}}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr v-for="(parcela, index) in model.parcelas" v-bind:key="index">
                                                    <template v-if="parcela.parcelaPagar">
                                                        <td></td>
                                                        <td>
                                                            <label class="label">Pago em</label>
                                                            <b-datetimepicker v-model="parcela.dataDePagamento" trap-focus></b-datetimepicker>
                                                        </td>
                                                        <td>
                                                            <ValidationProvider rules="required" name="parcela.especieDePagamento" v-slot="{ errors, valid }">
                                                                <b-field :label="$t('CONTASPAGARCADASTRO.ESPECIE')"
                                                                         :message="errors"
                                                                         :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                                                    <b-select :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERESPECIE')" v-model="parcela.especieDePagamento.id">
                                                                        <option v-for="option in especies"
                                                                                :value="option.id"
                                                                                :key="option.id">
                                                                            {{ option.nome }}
                                                                        </option>
                                                                    </b-select>
                                                                </b-field>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td v-if="parcela.especieDePagamento.nome == 'TransferenciaBancaria'">
                                                            <label class="label">Conta Corrente / Cartão</label>
                                                            <ValidationProvider rules="required" name="model.contasCorrentes" v-slot="{ errors, valid }">
                                                                <b-field :label="$t('CONTASPAGARCADASTRO.CONTACORRENTE')"
                                                                         :message="errors"
                                                                         :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                                                    <b-select :placeholder="$t('CONTASPAGARCADASTRO.PLACEHOLDERCONTACORRENTE')" class="is-fullwidth" v-model="model.contaCorrente.id">
                                                                        <option v-for="(item, i) in contasCorrentes" v-bind:key="i" :value="item.id">{{item.nome}}</option>
                                                                    </b-select>
                                                                </b-field>
                                                            </ValidationProvider>
                                                        </td>
                                                        <td v-if="parcela.especieDePagamento.nome == 'Cheque'">
                                                            <label class="label">{{$t('CONTASPAGARCADASTRO.CHEQUENUMERO')}}</label>
                                                            <b-input maxlength="10" type="text" v-model="parcela.chequeNumero"></b-input>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <td width="12%"></td>                                                    
                                                    <td width="12%"></td>
                                                    <td width="12%"></td>                                                    
                                                    <td width="12%"></td>    
                                                    <td width="12%"><b>{{ totalContas | currency }}</b></td>
                                                    <td width="40%" ></td>
                                                </tr>                                                
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="box">
                                        <article class="level">
                                            <div class="level-item">
                                                <button type="button" class="button is-info is-fullwidth" @click="addParcela()">
                                                    {{$t('CONTASPAGARCADASTRO.ADICIONARPARCELA')}}
                                                </button>
                                            </div>
                                        </article>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('CONTASPAGARCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CONTASPAGARCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState  } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    data: new Date(),
                    descricao: null,
                    funcionarioId: null,
                    unidadeId: null,
                    centroDeCusto: {
                        id: null,
                    },
                    contaCorrente: {
                        id: null,
                    },
                    fornecedor: {
                        id: null,
                    },
                    notaFiscal: this.$route.params.notafiscal ? this.$route.params.notafiscal : null,
                    observacoes: null,
                    pedidoId: this.$route.params.pedidoid ? this.$route.params.pedidoid : null,
                    parcelas: []
                },
                totalContas: 0.0,
                centrosDeCusto: [],
                contasCorrentes: [],
                fornecedores: [],
                especies: [],
                salvandoDados: false
            }
        },
        components: {
            titlebar,
            historicoAlteracoes,
            searchIdName
        },
        created() {
            if (this.$route.params.id) {
                const params = [];
                params.push(`id=${this.$route.params.id}`);

                if (this.$route.params.notafiscal)
                    params.push(`notaFiscal=${this.$route.params.notafiscal}`);

                if (this.$route.params.pedidoid)
                    params.push(`pedidoId=${this.$route.params.pedidoid}`);
                
                this.$http.get(`/api/financeiro/pagar?${params.join('&')}`)
                    .then(res => {
                        this.model = res.data;   
                        this.model.data = new Date(this.model.data);
                        
                        for (let i = 0; i < this.model.parcelas.length; i++) {
                            this.model.parcelas[i].dataDeVencimento = new Date(this.model.parcelas[i].dataDeVencimento);                            
                        }

                        this.recalcularParcelas();
                    })

            }


            this.$http.get('/api/search/centrosdecusto')
                .then(res => {
                    this.centrosDeCusto = res.data;
                });

            this.$http.get('/api/search/contascorrentes')
                .then(res => {
                    this.contasCorrentes = res.data;
                });

            this.$http.get('/api/search/fornecedores')
                .then(res => {
                    this.fornecedores = res.data;
                });

            this.$http.get('/api/search/especies')
                .then(res => {
                    this.especies = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('CONTASPAGARLISTAR.TITULO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config'
            ])
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;                
                this.$http.post(`/api/financeiro/pagar`, this.model )
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.push({ name: 'financeiro' });
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            },
            addParcela() {
                this.model.parcelas.push({
                    parcelaPagar: false,
                    id: null,
                    chequeNumero: null,
                    contaCorrente: {
                        id: null
                    },
                    dataDeCancelamento: null,
                    dataDePagamento: null,
                    dataDeVencimento: this.model.parcelas.length > 0 ? 
                                      this.$moment(this.model.parcelas[this.model.parcelas.length-1].dataDeVencimento).add(30, 'days').toDate() :
                                      new Date(),
                    especieDePagamento: { id: 1, nome:'Dinheiro'},
                    funcionarioCancelamento: {
                        id: null,
                        nome: null
                    },
                    funcionarioPagamento: {
                        id: null,
                        nome: null
                    },
                    motivoCancelamento: null,
                    valor: 0,
                    multa: 0,
                    juros: 0,
                    valorParcial:0,
                    situacao: 'Pendente',
                    pagar: {
                        id: null
                    }
                });
            },
            pagarParcela(index){
                this.model.parcelas[index].dataDePagamento = this.$moment().toDate();
                this.model.parcelas[index].situacao = 'Pago';
                this.model.parcelas[index].parcelaPagar = true;
            },
            cancelarParcela(index){
                this.model.parcelas[index].dataDeCancelamento = this.$moment().toDate();
                this.model.parcelas[index].situacao = 'Cancelado';
            },
            recalcularParcelas() {                
                
                this.totalContas = parseFloat(0.0);
                this.model.parcelas.forEach((p) => {

                    p.valor = (!p.valor) ? 0.0 : p.valor;   
                    p.valorParcial = ( !p.valorParcial) ? 0.0 : p.valorParcial;   
                    p.multa = (!p.multa) ? 0.0 : p.multa;   
                    p.juros = ( !p.juros) ? 0.0 : p.juros;   
                                        
                    if(p.valor>=0){            
                        
                        p.valor = (parseFloat(p.valorParcial?.toString()) + parseFloat(p.multa?.toString()) + parseFloat(p.juros?.toString())).toFixed(2);  
                        this.totalContas = parseFloat(this.totalContas) + parseFloat(p.valor);                        
                    }
                    
                });
                this.totalContas = parseFloat(this.totalContas).toFixed(2);  
                
                this.$forceUpdate();

            },            
        }
    }
</script>